@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700&display=swap');

@font-face {
  font-family: 'Satoshi';
  src: url('./assets/fonts/satoshi/Satoshi-Regular.woff2') format('woff2'),
       url('./assets/fonts/satoshi/Satoshi-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Satoshi-Medium';
  src: url('./assets/fonts/satoshi/Satoshi-Medium.woff2') format('woff2'),
       url('./assets/fonts/satoshi/Satoshi-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Poppins', 'Satoshi', 'Satoshi-Medium';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
